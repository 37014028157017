import * as _ from 'underscore';
import {trackPageView} from "../../services/tracking-helper";

'use strict';

angular.module('app').controller('OrderHistoryViewCtrl', ['$scope', '$rootScope', '$location', '$window', '$interval', '$timeout', '$http', '$element', 'Alerts',
  function ($scope, $rootScope, $location, $window, $interval, $timeout, $http, $element, Alerts) {
    console.log("OrderHistoryViewCtrl initialized");
    window.OrderHistoryViewCtrl = $scope;
    trackPageView("orders_history_page");

    $scope.perPage = 6;
    $scope.orderScope = 'past';
    $scope.templateIsRendered = false;
    $scope.orders = {
      current: {loaded: false, current_page: 1},
      past: {loaded: false, current_page: 1},
      incomplete: {loaded: false, current_page: 1},
    };

    $scope.loadOrders = function(scopeChange) {
      let currentPage = $scope.orders[$scope.orderScope].current_page;

      scopeChange = !!scopeChange;
      if (scopeChange)
        $scope.templateIsRendered = false;

      $http.get(`/api/frontend/orders/my.json?no_totals=t&locale=${I18n.locale}&per_page=${$scope.perPage}&page=${$scope.orders[$scope.orderScope].current_page}&scope=${$scope.orderScope}&line_item_product_ids=t&delivery_slots=t`).then((response) => {
        $scope.orders[$scope.orderScope] = _(response.data).omit('current_page');
        $scope.orders[$scope.orderScope].current_page = currentPage;
        $scope.orders[$scope.orderScope].loaded = true;

        $scope.updatePagination();

        if (scopeChange)
          $timeout(() => $scope.templateIsRendered = true, 1000)

        $('html, body').animate({ scrollTop: 0 }, 'slow');
      })
    };

    $scope.changeOrderScope = function(orderScope) {
      $scope.orderScope = orderScope || $scope.orderScope;

      if (!$scope.orders[$scope.orderScope].loaded)
        $scope.loadOrders(true)
    };

    $scope.onPageChanged = function(page) {
      if (page > 0 && page != $scope.orders[$scope.orderScope].current_page && page <= $scope.orders[$scope.orderScope].pages) {
        $scope.orders[$scope.orderScope].current_page = page;
        $scope.loadOrders(false);
      }
    };

    $scope.updatePagination = function() {
      let pages = $scope.orders[$scope.orderScope].pages;
      let currentPageNavigation = $scope.orders[$scope.orderScope].pageNavigation;

      if (pages > 1) {
        currentPageNavigation = [1, 2];
        if (pages > 2) {
          let currentPage = $scope.orders[$scope.orderScope].current_page;
          currentPageNavigation = [1, 2, 3];

          if (currentPage > 1) {
            if (currentPage < pages)
              currentPageNavigation = [currentPage - 1, currentPage, currentPage +1];
            else
              currentPageNavigation = [currentPage - 2, currentPage - 1, currentPage]
          }
        }
      } else
        return;

      $scope.isLastInView = currentPageNavigation.indexOf(pages) > -1;
      $scope.isFirstInView = currentPageNavigation.indexOf(1) > -1;

      $scope.orders[$scope.orderScope].pageNavigation = currentPageNavigation;
    };

    $scope.$watch('userId', function(val) {
      if (!UserService.isLoggedIn) UserService.showLoginScreen({forcePageReload: true});
    });

    $scope.loadOrders(true);

    $scope.$on('$destroy', function() {
      window.OrderHistoryViewCtrl = null;
    })
  }
]);
