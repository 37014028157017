import * as _ from 'underscore';
import * as moment from 'moment';

'use strict';

angular.module('app').controller('CheckoutPaymentAccountBalanceCtrl', ['$scope', '$sce', '$http', '$q', '$rootScope', '$timeout', '$element', 'Alerts', 'blockUI', 'UserService', function ($scope, $sce, $http, $q, $rootScope, $timeout, $element, Alerts, blockUI, UserService) {
  window.CheckoutPaymentAccountBalanceCtrl = $scope;

  // Public properties

  $scope.checkoutCtrl = $scope.$parent.$parent.$parent.$parent;
  $scope.paymentCtrl = $scope.$parent.$parent;

  /**
   *
   * @type {FormController}
   */
  $scope.accountBalanceForm = null;
  $scope.accountBalance = {};

  /**
   * Available account balance.
   *
   * @type {Float}
   */
  $scope.usableBalance = null;

  /**
   * If the order can be payed completely with account balance funds.
   * Initialized by a watcher.
   *
   * @type {boolean}
   */
  $scope.canPayFullOrder = null;

  $scope.recommendedPayment = 0.0;

  $scope.totalPlusSurcharge = null;

  //
  // Private members
  //

  var accountBalancePaymentMethod;

  function constructor() {
    $scope.$watch('selectedPaymentMethod', (newValue, oldValue) => {
      console.log('selectedPaymentMethod', newValue, getAccountBalancePaymentMethod());
      if (newValue != null && newValue.id == getAccountBalancePaymentMethod().id) {
        $scope.usableBalance = parseFloat(newValue.usable_balance);
      }
    })

    // Control which button action to display, depending on the entered payment amount
    $scope.$watch('accountBalance.amount', (newValue, oldValue) => {
      $scope.totalPlusSurcharge = $scope.checkoutCtrl.checkout.total + $scope.checkoutCtrl.checkout.weight_control_surcharge_total;
      if ($scope.accountBalance.amount && parseFloat($scope.accountBalance.amount) >= $scope.totalPlusSurcharge) {
        $scope.payingFullOrder = true
      } else $scope.payingFullOrder = false;
    })

    $scope.$watch('usableBalance', (newValue) => {
      if (newValue) {
        if ($scope.usableBalance >= $scope.totalPlusSurcharge) {
          $scope.canPayFullOrder = true;
          $scope.recommendedPayment = $scope.totalPlusSurcharge;
        } else {
          $scope.canPayFullOrder = false;
          $scope.recommendedPayment = $scope.usableBalance;
        }

        // Set current amount to the recommended amount, if it's empty
        if ($scope.accountBalance.amount == null) {
          $scope.accountBalance.amount = Math.round($scope.recommendedPayment * 100) / 100;
        }
      }
    });

    // Constructor is supposed to be called when the parent already has existing
    // checkout data loaded:
    $scope.accountBalanceAdjustments = $scope.getAccountBalanceDiscountAdjustments();
  }

  function destructor() {
    window.CheckoutPaymentAccountBalanceCtrl = null;
  }

  function getAccountBalancePaymentMethod() {
    if (accountBalancePaymentMethod == null) {
      accountBalancePaymentMethod = _.find($scope.checkoutCtrl.availablePaymentMethods, (m) => m.type == "Spree::PaymentMethod::AccountBalance");
    }

    return accountBalancePaymentMethod;
  }

  function validateAmount() {
    if ($scope.accountBalance.amount) {
      $scope.accountBalance.amount = parseFloat($scope.accountBalance.amount);

      if ($scope.accountBalance.amount > $scope.recommendedPayment)
        $scope.accountBalance.amount = $scope.recommendedPayment * 1.0;

      if ($scope.accountBalance.amount <= 0.01) {
        Alerts.error($translate.instant('errors.unknown_error')); // TODO: Add error text
        $scope.accountBalanceForm.amount.$error.min = true;
        return "amount_required";
      }
    } else {
      Alerts.error($translate.instant('errors.unknown_error')); // TODO: Add error text
      $scope.accountBalanceForm.amount.$error.required = true;
      return "amount_required";
    }
  }

  //
  // Public methods
  //

  /**
   * Submit callback for the payment form
   *
   * @returns {*}
   */
  $scope.resolveSubmit = function() {
    $scope.checkoutCtrl.trackAddPaymentInfo($scope.paymentCtrl.selectedPaymentMethod.name);
    return ($scope.payingFullOrder ? $scope.submitAccountBalancePayment() : $scope.submitPartialPayment())
  }

  /**
   * Fully pays the order with Guthaben
   *
   * @returns {*}
   */
  $scope.submitAccountBalancePayment = function() {
    if ($scope.paymentIsOngoing) {
      Alerts.info('Performing operation...');
      return
    }

    $scope.formError = null;
    $scope.paymentIsOngoing = true;

    blockUI.start();

    // Reset some custom validations
    if ($scope.accountBalanceForm.amount.$error)
      $scope.accountBalanceForm.amount.$error.min = null;

    var params = {
      payments_attributes: [
        {
          payment_method_id: getAccountBalancePaymentMethod().id,
          source_id: $scope.checkoutCtrl.userProfile.account.id,
          source_type: 'Spree::UserAccount'
        }
      ],
      payment_source: { }
    };

    params.payment_source[getAccountBalancePaymentMethod().id] = {
      id: $scope.checkoutCtrl.userProfile.account.id
    };

    return $q(function (resolve, reject) {
      $scope.accountBalanceForm.$setSubmitted();

      // Note: no longer relevant since we consider the tos accepted upon subscription
      // if ($scope.payingFullOrder && $scope.accountBalance.accepts_tos != true) {
      //   Alerts.error($translate.instant('errors.unknown_error')); // TODO: Add error text
      //   reject("accept_tos_required");
      //   blockUI.stop();
      //   return;
      // }

      // Validate amount
      var error;
      if (error = validateAmount()) {
          reject(error);
          blockUI.stop();
          $scope.paymentIsOngoing = false;
          return;
      }

      $scope.checkoutCtrl.updateCheckout(params).then((r) => {
        resolve(r);
        blockUI.stop();
        $scope.paymentIsOngoing = false;

        if (r.state == 'complete')
          console.log("Checkout was completed");
      }, (e) => {
        reject(e);
        blockUI.stop();
        $scope.paymentIsOngoing = false;
      });
    });
  };

  /**
   * Only creates a discount adjustment for the order for the guthaben amount,
   * without actually submitting the payment form. Used as form submit callback
   * when the amount is less than order total.
   */
  $scope.submitPartialPayment = function() {
    if ($scope.paymentIsOngoing) {
      Alerts.info("Performing operation...");
      return;
    }

    $scope.formError = null;
    blockUI.start();

    $scope.isUpdating = true;
    $scope.paymentIsOngoing = true;

    return $q((resolve, reject) => {
      // Reset some custom validations
      if ($scope.accountBalanceForm.amount.$error) {
        $scope.accountBalanceForm.amount.$error.min = null;
      }

      // Validate amount
      const error = validateAmount();
      if (error) {
        Alerts.error(error);
        reject(error);
        $scope.isUpdating = false;
        $scope.paymentIsOngoing = false;
        blockUI.stop();
        return;
      }

      // Request a discount adjustment for the order for the specified amount
      $http.post(`/api/frontend/orders/${$scope.checkoutCtrl.checkout.number}/add_discount_adjustment_from_account_balance.json`, {
        order_token: $scope.checkoutCtrl.checkout.token,
        amount: $scope.accountBalance.amount
      }).then(function(response) {
        Alerts.success($translate.instant('messages.account_balance_discount_created'));
        $scope.isUpdating = false;
        $scope.paymentIsOngoing = false;
        $scope.accountBalance.amount = null;

        UserService.loadAccount();

        // Reload checkout info to update totals
        return $scope.checkoutCtrl.getCheckout().then(() => {
          $scope.checkoutCtrl.getUserProfile();
          return $scope.checkoutCtrl.getPaymentMethods();
        }).then(() => {
          $scope.accountBalanceAdjustments = $scope.getAccountBalanceDiscountAdjustments();
          resolve(response.data);
        });
      }).catch((error) => {
        $scope.formError = error.data.error;
        Alerts.error(errorMessage(error));
        reject(error);
      }).finally(() => {
        blockUI.stop();
        $scope.isUpdating = false;
        $scope.paymentIsOngoing = false;
        $scope.accountBalance.amount = null;
      });
    });
  };

  // Initialize

  $scope.$on("$destroy", destructor);
  constructor();
}]);
