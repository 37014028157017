'use strict';

import {trackPageView} from "../../services/tracking-helper";

angular.module('app').controller('OrderDetailsViewCtrl', ['$scope', '$http', '$location', 'blockUI', 'Alerts', '$element', 'OrderHelper', 'CartData',
  function ($scope, $http, $location, blockUI, Alerts, $element, OrderHelper, CartData) {
    $scope.orderId = $element.data('order-id');
    trackPageView("order_details_page");

    $scope.setOrderAsCurrent = function(orderId) {
      $scope.CartData.doUpdateCart = false; // Switch off automatic cart syncing, to avoid conflicts between carts

      blockUI.start($translate.instant('messages.please_wait_while_loading'));

      return $http.post($scope.locationLocalePrefix + sprintf('orders/%d/set_to_current.json', $scope.orderId)).then(function(response) {
        location.href = $scope.locationLocalePrefix = '/cart';
      }, function (error) {
        Alerts.error(errorMessage(error));
        $scope.CartData.doUpdateCart = true;
      }).finally(() => {
        blockUI.stop()
      });
    };

    $scope.repeatOrder = function(sourceOrderId, sourceOrderToken) {
      blockUI.start();

      OrderHelper.repeatOrder({ id: sourceOrderId, token: sourceOrderToken}).then(() => {
        CartData.load().then(() => {
          blockUI.stop();
          $location.path('/cart')
        })
      }, e => {
        blockUI.stop()
      })
    };

    function loadOrder() {
      $http.get(`/api/orders/${$scope.orderNumber}.json`).then((response) => {
        $scope.order = response.data;
        console.log('order: ', $scope.order)
      })
    }

    let orderWatcher = $scope.$watch('orderNumber', () => {
      loadOrder();
      orderWatcher()
    });

    $scope.$watch('userId', function(val) {
      if (!UserService.isLoggedIn) UserService.showLoginScreen({forcePageReload: true});
    });

    $scope.$on('$destroy', function() {

    })
  }
]);
